import {
  EntidadesFinancieras,
  Estudios,
  Multiservicios,
  Salud,
  Seguros,
  Servicios,
  SolicitudConvenios,
  Transportes,
} from "../pdfs";

export const pdfList = [
  {
    id: "salud",
    title: "Convenios Salud",
    src: Salud,
    drive:
      "https://drive.google.com/file/d/1hUDli6AX5kGJgcpgtKvGZrFfw7j2leAf/view?usp=drive_link",
  },
  {
    id: "transportes",
    title: "Convenios Transportes",
    src: Transportes,
    drive:
      "https://drive.google.com/file/d/1Rhx9bzkZqExfVbdf6ARNTeK73FT7xMzK/view?usp=drive_link",
  },
  {
    id: "servicios",
    title: "Convenios Servicios",
    src: Servicios,
    drive: "https://drive.google.com/file/d/11DUUkgj5K8o3gA11n19Li0Vsu-FjI3CK/view?usp=sharing",
  },
  {
    id: "seguros",
    title: "Convenios Seguros",
    src: Seguros,
    drive:
      "https://drive.google.com/file/d/1DVewl2rcBzBn4Lbk9tRxePVhmk2CS_Nu/view?usp=drive_link",
  },
  {
    id: "entidadesFinancieras",
    title: "Convenios Entidades Financieras",
    src: EntidadesFinancieras,
    drive:
      "https://drive.google.com/file/d/1lyQbfoOoX94OkFl2v_d993yyBoGvBRTk/view?usp=drive_link",
  },
  {
    id: "estudios",
    title: "Convenios Universidades e Institutos",
    src: Estudios,
    drive:
      "https://drive.google.com/file/d/1QJariYXQQATL8crHmH4zPfM5jdwn0XDQ/view?usp=sharing",
  },
  {
    id: "multiservicios",
    title: "Convenios Multiservicios",
    src: Multiservicios,
    drive:
      "https://drive.google.com/file/d/1JBpXv3wOy0eGdtIL7wKibdTZqVkP4XyS/view?usp=sharing",
  },

  {
    id: "solicitudConvenios",
    title: "Solicitud de Convenios",
    src: SolicitudConvenios,
    drive:
      "https://drive.google.com/file/d/1TxueZ-lJlf0_zHZsQVmcZJQEa1biZLky/view?usp=sharing",
  },
];

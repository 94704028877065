import React from "react";
import styled from "styled-components";
import { MinisterioTrabajo, CompuTrab, MiCarrera } from "../../images";
import { Departments } from "../../components/public";
import { mediaQuery } from "../../styles/constants/mediaQuery";

const process = [
  {
    banner: MinisterioTrabajo,
    titleLarge: "Sistema de sector público",
    externalUrl: "https://www.empleosperu.gob.pe/portal-mtpe/",
  },
  {
    banner: CompuTrab,
    titleLarge: "Sistema de sector privado",
    externalUrl: "https://pe.computrabajo.com/",
  },
];

export const JobOpportunity = () => {
  return (
    <Container>
      <WrapperComponents>
        <div className="item-title">
          <h2>
            El "COBIENE" pone a disposicion las oportunidades laborales de
            trabajo
          </h2>
          <h2>
            dentro de los sistemas del Estado Peruano como tambien el sector
            privado
          </h2>
        </div>

        <Departments
          titleLargeDepartment="Procesos"
          departments={process}
          margin={"1em 0 1em 0"}
        />
      </WrapperComponents>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;

  .item-title {
    padding: 4em 1em 0 1em;
    h2 {
      text-align: center;
    }
  }
`;
const WrapperComponents = styled.div`
  width: 100%;
  max-width: 1250px;
  height: auto;
  margin: auto;
  padding: 1em;
  ${mediaQuery.minTablet} {
    padding-top: 130px;
  }
`;
